@import "variables";


:root {
  --input-padding-x: 0;
  --input-padding-y: 10px;
}

// Placeholder
%modalCloseBtn {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 60px;
  font-weight: 100;
  color: #b6b8ba;

  &:focus {
    outline: none;
  }
}

%mandatoryStar {
  content: '*';
  color: $error_color;
  padding-left: 3px;
}

// Placeholders #


body {
  font-family: $font-secondary, sans-serif;
  background: $cc-background-color;
}

router-outlet.main-content ~ * {
  position: absolute;
  height: 100%;
  left: 220px;
  right: 0;

  .container-fluid {
    padding-right: 60px;
    padding-left: 60px;
    background-color: $mc-gray-background;
    transition: all 250ms linear;
  }
}

.blurred {
  filter: blur(5px);
  transition: all 250ms linear;
}

router-outlet ~ * {
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
}

.valign-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  min-height: 100%;

  &.full-height {
    min-height: 100vh;
  }
}

.mc-mandatory-star::after {
  @extend %mandatoryStar;
}

.v-container {
  height: 100vh;
  display: table;
  width: 100%;
}

.valign {
  vertical-align: middle;
  display: table-cell;
  padding: 100px 0;
}
.mc-overflow-hidden {
  overflow: hidden;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $third_color_alt;
  z-index: 1039;
  overflow-y: scroll;
}

.info-data {
  .info-container-secondary-billing {
    background-color: #F8f8f8;
    border-radius: 4px;
    padding: 10px 16px;
    margin: 0 0 10px 0;
    padding: 20px;
    height: 100%;


    span {
      text-transform: none;
      font-size: 1rem;
      font-weight: 400;
      margin: 0 10px;
    }

  }

  .info-container-billing {
    background-color: #F8f8f8;
    border-radius: 0 0 4px 4px;
    padding: 10px 16px;
    font-size: 16px !important;

    span {
      text-transform: none;
      margin-left: 5px;
      font-size: 1rem;
      font-weight: 400;
    }

  }
}


.floating-content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1039;
}

// login area
.mc-login-btn-area {
  padding-top: 70px;

  .mc-login-btn {
    width: 50%;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }
}

.mc-input-password {
  position: relative;
}

.mc-active {
  color: $login-blue-color;
}

.mc-semi-active {
  color: lighten($login-blue-color, 15%);
}

.mc-active-background {
  background-color: $login-blue-color;
}

.mc-semi-active-background {
  background-color: lighten($login-blue-color, 15%);
}

.mc-error-input-wrapper {
  border-color: $error_color !important;
}

.mc-success-button {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  border: none;
  background: $login-blue-color;
  color: $bg_color;
  padding: 5px 0;

  &:focus {
    outline: none;
  }

  &:hover {
    background: darken($login-blue-color, 10%);
  }
}

.mc-close-button {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  border: none;
  background: darken($cancel-button-color, 8%);
  color: $bg_color;
  padding: 5px 30px;

  &:focus {
    outline: none;
  }

  &:hover {
    background: darken($cancel-button-color, 14%);
  }
}

.mc-date {
  font-weight: 500;
}

.mc-warning-button {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  border: none;
  background: $error_color;
  color: $bg_color;
  padding: 5px 0;

  &:focus {
    outline: none;
  }

  &:hover {
    background: darken($error_color, 10%);
  }
}

.mc-login-card {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  .mc-login-header {
    padding-left: 40px;
    padding-right: 40px;

    img {
      margin: 30px 0 0 0;
    }

    h4 {
      text-align: center;
      line-height: 1.5;
      margin-bottom: 0;
    }
  }

  .mc-card-title {
    margin-top: 30px;
  }


  .boarding-btn {
    background-color: $login-blue-color;
    color: white;
    border: 1px solid $login-blue-color;
  }

  .mc-divider {
    border-right: 1px solid $login-gray-color;
  }
}

.mc-row-no-margin {
  margin: 0;
}

.mc-arrow {
  color: $white;
  background-color: $mc-blue-text-color;
  font-size: 14px;
  padding: 2px 5px 1px 5px;
  margin-left: 10px;
  margin-bottom: 2px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  vertical-align: middle;
  cursor: pointer;

  &.mc-arrow-sidebar {
    width: 20px;
    height: 18px;
    text-align: center;
  }
}

.mc-notification-badge-gray {
  background: $login-gray-color;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: $white;
  font-size: 11px;
  line-height: 1;
  padding: 3px 6px;
  margin-right: 5px;
}

.mc-notification-badge-blue {
  background: $light-blue-color;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: $white;
  font-size: 11px !important;
  line-height: 1;
  padding: 3px 6px;
  margin-right: 5px;
  position: absolute;
  bottom: 3px;
}


.mc-stepper-header-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: darken($login-gray-color, 10%);

  span:not(.mc-notification-badge-gray) {
    font-size: 20px;
    position: relative;
  }

  .mc-notification-badge-gray {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

/*Checkbox all*/
.mc-custom-checkbox {
  display: inline-block;
  height: 12px;
  margin-top: -20px;
  margin-left: -24px;
  padding: 0;

  &.fill-checkbox {
    --color: #35B1F1;

    .fill-control-input {
      display: none;

      &:checked ~ .fill-control-indicator {
        background-color: var(--color);
        border-color: var(--color);
        background-size: 80%;
      }
    }

    .fill-control-indicator {
      position: absolute;
      top: 19px;
      left: 0;
      width: 14px;
      height: 14px;
      background-color: $mc-white-background;
      border: 1px solid #aaa;
      background-size: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    }

    .fill-control-description {
      font-size: 14px;
    }
  }
}

.mc-error-field {
  border-color: $error_color;

  &:focus {
    border-color: $error_color;
    box-shadow: 0 0 0 0.2rem rgba(236, 35, 13, .25)
  }
}

.mc-selected-row {
  background: $table-selected-row;
}

.mc-font-semi-weight {
  font-weight: 500;
}

.mc-font-semi-weight-1 {
  font-weight: 600;
  font-size: 80%;
}

.font {
  font-size: 1rem;
  font-weight: 400;
}

.mc-next-save-btn {
  background: darken($mc-blue-text-color, 15%);
  border: none;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 20px;

  &:focus {
    box-shadow: none;
  }

  &:hover {
    background: darken($mc-blue-text-color, 20%);
  }
}

.mc-prev-btn {
  border: none;
  border-radius: 20px;
  padding-left: 30px;
  padding-right: 30px;
}

.mc-close-btn {
  position: absolute;
  right: 11px;
  top: 0;
}

.mc-cancel-btn {
  border: none;
  //background: transparent;
  background: #990000;
  //border: 1px solid #990000;
  border-radius: 25px;
  //color: #990000;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 25px;
  text-decoration: none;
  //transition: 0.25s all ease-in-out;
  &:hover{
    background: darken(#990000, 2%);
    color: #ffffff;
  }
  &:focus{
    outline: none;
  }
}

.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/*Go back button in edit management component*/
.mc-go-back-to-all {
  display: flex;
  align-items: center;
  color: $login-blue-color;
  font-size: 16px;
  font-weight: 500;
  padding: 5px;
  margin-bottom: 15px;
  cursor: pointer;
  font-weight: bold;
  width: fit-content;

  &:hover {
    color: $mc-header-color;
  }

  i {
    font-size: 20px;
    margin-right: 10px;
  }
}


/* Dates */
.mc-dates-inputs[readonly] {
  background: #fff;
}

// Header Info
.header-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  background-color: $mc-header-color;
  color: $mc-white-background;
  border-radius: 5px;
  min-height: 50px;
  margin-bottom: 20px;

  p {
    font-size: 18px;
    padding: 0px 10px;
    margin: 0px;
  }

  span {
    font-weight: 500;
    padding-left: 5px;
  }
}

.header-info-light {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  box-shadow: $general-shadow;
  border-radius: $border-radius-small;
  min-height: 50px;
  user-select: none;
  padding: 0px 20px;

  p {
    font-weight: 500;
    font-size: 18px;
    padding: 0px 10px;
    margin: 0px;
  }

  span {
    font-weight: 700;
    padding-left: 5px;
  }
}

/*ngb-datepicker {
  .ngb-dp-header.bg-light {
    background-color: $mc-datepicker-bg !important;

    .ngb-dp-arrow .ngb-dp-arrow-btn {
      color: $mc-datepicker-date;

      &:focus {
        outline: none;
      }

      .ngb-dp-navigation-chevron {
        width: 9px;
        height: 9px;
        border-width: 2px 2px 0 0;
      }
    }

    .ngb-dp-navigation-select {
      flex: 1 1 66px;

      .custom-select {
        background: $mc-datepicker-bg none;
        border: 0;
        border-radius: 0;
        color: $mc-datepicker-date;
        font-size: 17px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 0 .3rem;

        &:first-of-type {
          padding-right: 0;
        }

        &:last-of-type {
          padding-left: 0;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

  }

  .ngb-dp-months {
    background-color: $mc-datepicker-bg;

    .ngb-dp-weekdays {
      background-color: $mc-datepicker-week !important;
      margin-top: 5px;
      margin-bottom: 5px;
      border-bottom: 0;
    }

    .ngb-dp-weekday {
      color: #585c60;
      font-style: normal;
      font-size: 17px;
    }

    .ngb-dp-week {
      font-size: 17px;
    }

    .ngb-dp-day div.bg-primary {
      background-color: $mc-datepicker-day-selected !important;
      color: #000 !important;
      border-radius: 0;
    }

    .ngb-dp-day:focus {
      box-shadow: none;
      border: 0;
      outline: none;
    }
  }
}*/

/* Dates #*/


.mc-chevron {
  color: $dd-grey-color-3;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);

  &::before {
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: "";
    display: inline-block;
    height: 0.45em;
    position: relative;
    transform: rotate(-45deg);
    width: 0.45em;
  }

  &.right:before {
    left: 0;
    transform: rotate(45deg);
  }

  &.left:before {
    left: 0.25em;
    transform: rotate(-135deg);
  }

  &.bottom:before {
    top: 0;
    transform: translateY(-60%) rotate(135deg);
  }
}

.mc-date-ico {
  &-0 {
    width: 24px;
    height: 24px;
    background-position: 0px -288px !important;
  }

  &-1 {
    width: 24px;
    height: 18px;
    background-position: -25px -284px !important;
  }

  &-2 {
    width: 24px;
    height: 18px;
    background-position: -50px -284px !important;
  }

  &-3 {
    width: 24px;
    height: 18px;
    background-position: -71px -284px !important;

  }

  &-4 {
    width: 24px;
    height: 18px;
    background-position: -95px -284px !important;
  }

  &-5 {
    width: 24px;
    height: 18px;
    background-position: -117px -284px !important;
  }
}


.mc-attribute-info {
  background-color: #D4E7F0;
  border-radius: 11px;
  width: 18px;
  height: 18px;
  padding: 3px 3px 3px 2px !important;

  &:hover {
    background-color: $mc-info-btn-color !important;
  }
}

.mc-custom-filter-dropdown {
  border: 0 !important;
  position: relative;

  .mc-dropdown-text:last-of-type {
    border-right: 1px solid $dd-grey-color-1;
  }


  &.show {
    .mc-dropdown-text {
      background: $dd-grey-color-1;
    }
  }

  .mc-dropdown-text {
    border-left: 1px solid $dd-grey-color-1;
    padding: 5px 30px 5px 20px;
    background: $dd-grey-color-2;
    cursor: pointer;
    position: relative;

    &::after {
      display: none;
    }
  }

  .mc-amount-due {
    form {
      height: 175px;
      width: 230px;
      text-align: center;
    }

    .apply-btn {
      position: absolute;
      bottom: 10px !important;
      right: 10px !important;
      font-size: 80%;
      width: 40%;

      &:after {
        display: none;
      }
    }

    .mc-dropdown-item {
      margin: 0;
      padding: 15px;
      padding-left: 0 !important;
      align-items: center;
      width: 80%;

      input {
        position: absolute;
        right: 10px;
        width: 40%;
        height: 20%;
      }

      label {
        width: 70%;
        font-size: 14px;
        color: $dd-grey-color-3;
        font-weight: 500;
        margin-bottom: 0;
        text-align: center;
      }
    }
  }

  .mc-dropdown-list {
    background: $dd-grey-color-1;
    border: none;
    border-radius: 0;
    box-shadow: 0 4px 8px -3px rgba(0, 0, 0, 0.4);
    padding: 0;
    margin: 0;
    min-width: 180px;
    left: auto !important;
    right: 0 !important;
  }

  li {
    border-bottom: 1px solid;
    border-color: darken($dd-grey-color-1, 10%);
    color: $dd-grey-color-3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 2.2;
    padding: 5px 20px;

    &:hover {
      cursor: pointer;
      background-color: $dd-grey-color-3;
      color: $bg_color;

      .mc-date-ico {
        &-0 {
          background-position: 0px -312px !important;
        }

        &-1 {
          background-position: -25px -301px !important;
        }

        &-2 {
          background-position: -50px -301px !important;
        }

        &-3 {
          background-position: -71px -301px !important;
        }

        &-4 {
          background-position: -95px -301px !important;
        }

        &-5 {
          background-position: -117px -301px !important;
        }
      }

      &:last-child {
        &::after {
          color: #fff;
        }
      }
    }

    &:last-child {
      border-bottom: 0;
      justify-content: flex-end;

      &::after {
        color: $dd-grey-color-3;
        position: relative;
        transform: rotate(45deg);
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: "";
        display: inline-block;
        height: 0.45em;
        width: 0.45em;
        margin-left: 5px;
      }

    }
  }
}

// Tabs style
.mc-details-tabs {
  width: 100%;

  ul {
    justify-content: center !important;
  }

  li {
    font-size: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  a {
    color: $mc-gray-line;

    &:hover {
      color: lighten($login-blue-color, 15%);
      cursor: pointer;
    }

    &.nav-link.active {
      color: $login-blue-color;
      border-bottom: 3px solid $login-blue-color;
      padding-bottom: 0;
    }
  }
}

.disable-input {
  background-color: #f0f0f5 !important;
  color: #f0f0f5 !important;
  pointer-events: none;
}

.disable-label {
  opacity: 0.3;
  pointer-events: none;
}

.contact-person-wrapper {
  border: 3px solid #b6b8ba;
  padding: 5%;
  margin-bottom: 15px;
  position: relative;
}

.contact-person {
  background: #f5f5f5;
  color: #b6b8ba;
  position: absolute;
  top: -14px;
  padding: 0 20px;
}

.set-font {
  font-size: 12px;
  color: $mc-gray-border-color;
  font-weight: 600;
  padding-right: 30px;
}

.position-top {
  margin-top: -1px;
  margin-left: 0;
}

.low-opacity {
  opacity: 0.3;
}

.arrow-position {
  position: absolute;
  left: 230px;
  top: 9px;
}

.active-link {
   color: $mc-white-background !important;
 }

.dropdown {
  position: relative;
  width: 270px;
  background: $filter-bg;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0px 2px 2px 0px;
  display: flex;
  align-items: center;
}

.dropdown-collapsed {
  width: 50px;
  height: 46px;
}

.custom_tool_tip,
.tabs_tool_tip,
.tabs_tool_tip_active {
  .mat-mdc-tooltip-surface {
    height: 40px;
    width: max-content !important;
    border-radius: 4px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    font-size: $middle-font !important;
    font-weight: 700 !important;
    padding: 12px !important;
    text-align: center !important;
  }
}

.custom_tool_tip {
  .mat-mdc-tooltip-surface {
    background: $filter-bg !important;
    color: $cc-subtitle-color !important;
    position: absolute;
    left: -66px;
  }
}

.tabs_tool_tip,
.tabs_tool_tip_active {
  .mat-mdc-tooltip-surface {
    position: absolute;
    left: 30px;
    top: -70px;
  }
}

.tabs_tool_tip {
  .mat-mdc-tooltip-surface {
    background: $filter-bg !important;
    color: $cc-subtitle-color !important;
  }
}

.tabs_tool_tip_active {
  .mat-mdc-tooltip-surface {
    background: $cc-primary-color !important;
    color: $filter-bg !important;
  }
}

.mc-sidebar-submenu {
  position: relative;
  margin-left: -19px;
  padding: 0;
  z-index: 1;
  background: $filter-bg;
  width: 279px;
  overflow-y: hidden;
  transition: all 0.5s ease-in-out;

  .submenu-item {
    font-size: 20px;
    text-decoration: none;
    margin-top: 2px;



    .submenu-item-hover {
      width: 100%;
      .mc-sidebar-link:last-of-type {
        padding-bottom: 0px !important;
      }

      .mc-sidebar-icon.dropdown-icon,
      .dropdown-link.mc-sidebar-link {
        font-size: 13px !important;
        width: 100%;
      }

      &:hover {
        a, i {
          color: $mc-white-background;
        }
      }
    }
    .dropdown-link, .dropdown-icon {
      text-decoration: none;
      color: #8aa4af;
      font-weight: bold;
      font-size: 14px;
    }
  }
}

.left-border {
  border-left: 3px solid $mc-blue-text-color;
}

.mc-card-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  content: "";
  width: 30px;
  height: 30px;
  top: -1px;
  left: -1px;
  transform: translateX(-50%);
  border-radius: 50%;
  color: #FFF;
  background-color: $mc-blue-text-color;
  z-index: 1;
}

/*Drag and drop icon*/
.mc-drag-and-drop {
  color: #ccc;

  &:hover {
    cursor: pointer;
  }
}

.mc-text-align-right {
  text-align: right;
}

.mc-text-align-center {
  text-align: center;
}

.mc-text-align-left {
  text-align: left;
}

.mc-blue-btn {
  border: 1px solid $mc-blue-text-color;
  color: $mc-blue-text-color;
  &:hover {
    background: $mc-blue-text-color;
    color: $mc-white-background;
  }
}

.mc-table-button {
  border-radius: 20px;
  width: 110px;
  text-align: center;
}

.mc-detect-html-changes {
  -webkit-animation: onChangeHtmlValue 2s infinite;
  -o-animation: onChangeHtmlValue 2s infinite;
  animation: onChangeHtmlValue 2s infinite;
}

.mc-input-field-wrapper {
  input:required {
    box-shadow: none;
  }
}

.mc-highlight-grey {
  background: darken($mc-gray-background, 7%);
  border-radius: 5px;
  padding: 3px 8px;
}

.mc-mandatory-border {
  border: 2px solid $error_color !important;

  &:focus {
    border-color: $error_color;
    box-shadow: 0 0 0 0.2rem rgba(236, 35, 13, .25) !important;
  }
}

[type="date"]::-webkit-inner-spin-button,
[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}


.mc-table-detect-changes-once {
  -webkit-animation: onChangeHtmlValue 2s infinite;
  -o-animation: onChangeHtmlValue 2s infinite;
  animation: onChangeHtmlValue 2s infinite;
  animation-iteration-count: 0;
}

@keyframes onChangeHtmlValue {
  0% {
    background-color: $dd-grey-color-3;
  }
  100% {
    background-color: $mc-datepicker-day-selected;
  }
}

.mc-icon {
  width: 18px;
  height: 18px;
  display: inline-block;

  * {
    width: 100%;
    height: 100%;
  }
  &:hover {
    cursor: pointer;
    opacity: .7;
  }
}

.mc-custom-link {
  width: 100%;
  color: $mc-blue-text-color;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}

.clear {
  clear: both;
}

.mc-background-loading {
  background: #dae9ff;
  color: #0081be;
}

.mc-font-weight-500 {
  font-weight: 500;
}

.mc-font-weight-600 {
  font-weight: 600;
}

/*For all confirm buttons*/
.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .mc-filter-button {
    background: $login-blue-color;
    border: none;
    outline: none;
  }
}

.mc-blue-link {
  color: $mc-blue-text-color !important;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none !important;

  &:hover {
    color: darken($mc-blue-text-color, 10%) !important;
  }
}

.mc-blue-button {
  color: #35B1F1;
  background-color: #fff;
  cursor: pointer;
  font-weight: 500;
  padding: 6px 10px;
  margin: 5px;
  border-radius: 6px;
  border: 1px solid #35B1F1;
}

.mc-button-grayout {
  color: gray;
  border-color: gray;
  opacity: 35%;
}

.mc-message-info {
  position: relative;
  margin-top: 30px;
  top: -33px;
}

.mc-unselectable-content-on-press-shift {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.mc-width-fit-content {
width: fit-content;
}

.mc-width-webkit-fill-available {
  width: -webkit-fill-available;
}

.defaultLengthForInputField {
  width: 220px;
}

.radio-button-error {
  &:before {
    border: 1px solid  $mc-red-color-text !important;
  }
}

.mc-factoring-textbox-filter {
  width: fit-content;
  display: inline-block;
}

.mc-notify-msg {
  position: relative;
  margin-top: 37px;
  top: -37px;
}

.mc-tooltip-top-margin {
  font-size: 1.3rem;
  margin-top: 39px;
  color: #35B1F1;
}
.mc-tooltip {
  font-size: 1.4rem;
  color: #35B1F1;
}
.mc-tooltip-length .tooltip-inner{
  min-width: fit-content;
  white-space: nowrap;
}

.mc-tooltip-length-350 .tooltip-inner{
  min-width: 250px;
}

.mc-disable-button {
  pointer-events: none;
  opacity: 0.3;
}

.select-in-component-width-50 {
  select {
    width: 50%;
  }
}
.mc-background-light-blue {
  background-color: #E5F8FF;
  min-height: 100%;
}
.mc-light-blue-btn {
  background-color: #3EB2EF !important;
  border-color: #3EB2EF !important;
}
.mc-background-light-grey {
  background-color: #FAF8F8;
}
.mc-background-gray {
  background: #f3f3f3;
  &.min-height {
    min-height: 100%;
  }
}
.mc-light-green-text {
  color: #28a745;
}
.mc-higher-box {
  line-height: 3;
}
.mc-border-small-radius {
  border-radius: 4px;
  .title {
    font-weight: 600;
  }
}
.mc-blue-icon {
  color: #35B1F1 !important;
}

.mc-disable {
  pointer-events: none;
  opacity: 0.3;
}

.mc-disabled-table-row {
  opacity: .35;
  pointer-events: none;
}
.mc-button-link {
  background: none;
  color:  #35B1F1;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.mc-tooltip-top-margin-40 {
  margin-top: 40px;
}
.border-test {
  border: 1px solid red;
}
.mc-pointer-events-none {
  pointer-events: none !important;
}
.text-orange-color {
  color: #F47407;
}
.icon-tooltip {
  position: relative;
  display: flex;

  img {
    width: 24px;
    margin-right: $margin-small;
  }

  .fa-pause-circle{
    font-size: 24px;
    margin-right: $margin-small;
  }
}
.icon-tooltip .icon-tooltip-text {
    visibility: hidden;
    min-width: 150px;
    max-width: 250px;
    background-color: #FFFFFF;
    color: #000000;
    text-align: center;
    border-radius: 4px;
    padding: 8px 4px;
    position: absolute;
    z-index: 1;
    top: 115%;
    left: 50%;
    margin-left: -60px;
    font-size: 12px;
    box-shadow: 0 0 12px rgba(0,0,0,0.2);
}
.icon-tooltip .icon-tooltip-text:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 35%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
.icon-tooltip:hover .icon-tooltip-text {
  visibility: visible;
}
.icon-tooltip:hover .icon-tooltip-hidden {
  visibility: hidden;
}
.radio-button-info-message {
  margin-top: 9% !important;
  .tooltip {
    position: absolute;
    top: 41% !important;
  }
  &:first-of-type i {
    padding-bottom: 12px;
  }
}
.mc-dropdown-menu {
  .dropdown-menu.show {
    top: 0 !important;
    left: 0 !important;
    will-change: transform !important;
    position: absolute;
    transform: translate(-76px, 44px) !important;
  }
}
.mc-position-relative {
  position: relative;
}
.mc-width-30-percetage {
  width: 30%;
}

.text-uppercase {
  text-transform: uppercase;
}
.mc-html-blue-button {
  height: 20px;
  line-height: 0.5;
  background: #3EB2EF;
  border-color: #3EB2EF;
  color: #FFFFFF;
}
.mc-html-gray-button {
  background: #6C757C;
  color: #FFFFFF;
}
.product-quantity {
  display: contents;
}

#toast-container > .toast-error {
  color: red;
  background-color: #FFFFFF;
  width: 450px;
}
.toast-error {
  background-image: url("/assets/images/times-circle-solid.svg") !important;
}
#toast-container{
  margin-top: 25px;
}
#toast-container > .toast-success {
  color: green;
  background-color: #FFFFFF;
  width: 360px;
}
.toast-success {
  background-image: url("/assets/images/check-circle-solid.svg");
}
#toast-container > div {
  opacity:1;
  z-index: 11111;
}

.toast-message a {
  color: $mc-blue-text-color;

  &:hover {
    color: darken($mc-blue-text-color, 20%);
  }
}


