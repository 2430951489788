/*Dashboard wrapper*/
.mc-dashboard-wrapper {
  padding: 0 40px;
  h1 {
    font-size: 32px;
    color: #111245;
  }

  .mc-dashboard-separator-wrapper {
    position: relative;
    height: 1px;
    width: 100%;

    .mc-dashboard-separator {
      content: "";
      position: absolute;
      top: 0;
      left: 5%;
      right: 5%;
      width: 90%;
      height: 2px;
      background-image: linear-gradient(to right, #3031, #30313369, #3031);
    }
  }
  .mc-dashboard-welcome-box {
    width: 100%;
    border: 1px solid #EEF4FC;
    box-shadow: #00000017 0 3px 20px;
    height: 98px;
    background: #FFFFFF;
    margin-top: 12px;
    border-radius: 6px;

    .mc-dashboard-info-icon {
      align-self: center;
      margin-left: 15px;
    }
    .mc-dashboard-welcome-message {
      align-self: center;
      margin: 15px 0 0 15px;
      h3 {
        color: #2C3A45;
        font-size: 22px;
      }
      p {
        font-size: 16px;
        color: #646262;
      }
    }
  }

  .mc-dashboard-card-col-wrapper {
    padding: 0;
    width: 100%;

    .mc-dashboard-card-heading {
      font-size: 20px;
      font-weight: 500;
      padding-bottom: 20px;
      text-transform: capitalize;
    }

    .mc-dashboard-card-row {
      justify-content: space-between;
    }

    .mc-dashboard-card-empty {
      max-width: 30.5%;
      height: 120px;
      width: 350px;
    }


  }
}

/*todo: need refactoring, cannot write scss code for sidebar in dashboard scss file (this is current solution until we done refactoring)*/
.mc-dashboard {
  height: 100%;
  // background: #F8F8F8;
  &.mc-dashboard-gray {
    background: #F8F8F8;
  }
  /*mc-sidebar-collapsed*/
  .mc-sidebar-wrapper.mc-sidebar-collapsed {
    width: 44px;
    display: inline-block;
    padding-left: 15px;
  }

  /*mc-content-width*/
  .mc-content-width {
    width: 100%;
    display: inline-block;
    margin-top: 50px;
    padding-left: 125px;
    padding-right: 40px;
  }

  //.mc-sidebar {
  .mc-sidebar-wrapper {
    width: 260px;
    flex: 1;
    max-width: 260px;
    margin-top: 60px;
    z-index: 60;

    .mc-sidebar-first-child {

      padding-top: 65px;
      background: $filter-bg;
      position: fixed;
      top: 0;
      left: 0;
      width: inherit;
      max-width: inherit;
      z-index: 9;
      height: 100vh;
    }

    /*Collapse button*/
    .mc-sidebar-collapse-button-wrapper {
      position: fixed;
      display: inline-block;
      top: 55px;
      z-index: 99;
      color: $white;
      cursor: pointer;
      font-size: 25px;

      /*Entity name*/
      .mc-entity-name {
        vertical-align: middle;
        color: lighten($mc-blue-text-color, 20%);
        cursor: auto;
      }
    }
  }

  /*MC content container*/
  .mc-content-container {
    padding-top: 75px !important;
    max-width: 100%;
    width: 100%;
    flex: 2;
    margin-top: 60px;
    padding-right: 40px;
    padding-left: 40px;
  }

  .mc-sidebar-header {
    padding: 15px 15px 20px 20px;
    border-bottom: 1px solid $mc-silver-border-color;

    img {
      box-sizing: content-box;
      margin-bottom: 40px;
      height: 30px;
    }

    .mc-sidebar-header-title {
      color: $mc-blue-text-color;
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 1;
    }
  }

  .mc-sidebar {

    /*Vertical scrollbar*/
    .mc-scrollbar-vertical::-webkit-scrollbar {
      width: 0;
      background-color: $mc-gray-background;
    }

    .mc-scrollbar-vertical::-webkit-scrollbar-thumb {
      border-radius: 15px;
      background-color: $mc-silver-border-color;

      &:hover {
        background-color: $mc-gray-border-color;
        cursor: pointer;
      }
    }


    .mc-sidebar-body {
      padding: 5px 15px 20px 0;
      height: calc(100vh - 65px);
      overflow-y: scroll;
      overflow-x: hidden;
      position: absolute;
      z-index: 1;

      /*todo: do not use element selector, only class*/
      .sidebar-list {
        list-style: none;
        padding: 0;

        .sidebar-list-item {
          margin-bottom: 5px;
          border-radius: 0px 2px 2px 0px;
          background: $filter-bg;
          width: 260px;

          &_collapsed {
            width: 50px;
            height: 46px;
          }

          /*Sidebar icon*/
          .mc-sidebar-icon {
            display: inline-block;
            font-size: 14px;
            position: relative;
            z-index: 9;
            padding: 15px 8px 15px 15px;
            color: $cc-subtitle-color;
            cursor: pointer;
            width: 30px;
            height: auto;
            margin-right: 6px;
            outline: none;

            //&:hover{
            //  color: $mc-white-background;
            //}

            &.active {
              color: $mc-gray-text-2;
            }
          }

          /*Mc sidebar link*/
          .mc-sidebar-link,
          .mc-sidebar-link-no-subitems {
            cursor: pointer;
            display: inline-block;
            padding: 8px 20px 10px 25px;
            letter-spacing: 1px;
            font-weight: 600;
            font-size: $middle-font;
            line-height: 19px;
            color: $cc-subtitle-color;
            min-height: 40px;
            margin-top: 5px;
          }

          .mc-sidebar-link {
            &.active {
              color: $filter-bg;
            }
          }

          .mc-sidebar-link-subitem {
            padding: 10px 20px 10px 25px;
          }

          .dropdown:hover {
            .mc-sidebar-icon.mc-submenu,
            .mc-menu-arrow-toggle,
            .mc-sidebar-link.mc-submenu {
              color: $cc-subtitle-color;
            }
          }

          .mc-sidebar-links:focus {
            background:  linear-gradient(267.71deg, #60C1C7 0%, #4E358C 100%) !important;
            .mc-sidebar-link,
            .mc-sidebar-icon {
              color: $filter-bg;
            }
          }

        }

      }
    }
  }
  .sidebar-collapsed-page-content {
    .mcc-slide-action-set-collapsed {
      left: 65px;
    }
  }


}
